export interface Config {
  auth0: {
    domain: string;
    clientId: string;
  };
  api: {
    private_url: string;
    public_url: string;
  };
  sentry: {
    enabled: boolean;
  };
  app: {
    defaultMobileRoom: string;
    defaultDesktopRoom: string;
    disableZoneJump?: boolean;
  };
  landingPage: {
    heroLogo: string;
    hideMenu?: boolean;
    menuLogo: string;
    menuLogoHref: string;
    backgroundVideo: string;
    backgroundVideoPoster?: string;
    links: {
      label: string;
      href: string;
    }[];
  };
}

const dev: Config = {
  auth0: {
    domain: "pestex.eu.auth0.com",
    clientId: "JFVVaGWPlQE6Go86pB9y9LuIHEWoNTKJ",
  },
  api: {
    private_url: "https://p2sm60fb78.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://bqp6b8wknd.execute-api.eu-west-2.amazonaws.com",
  },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "festiverse",
    defaultDesktopRoom: "conferenceRoom",
  },
  landingPage: {
    backgroundVideo: "assets/videos/zonevs-bgvideo.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/zonevs-logo-welcome.svg",
    menuLogo: "assets/logos/zonevs-logo.png",
    menuLogoHref: "https://zonevs.io",
    links: [
      {
        label: "About",
        href: "https://zonevs.io/about",
      },
      {
        label: "Pricing",
        href: "https://zonevs.io/pricing",
      },
    ],
  },
};

const prod: Config = {
  auth0: {
    domain: "pestex.eu.auth0.com",
    clientId: "JFVVaGWPlQE6Go86pB9y9LuIHEWoNTKJ",
  },
  api: {
    private_url: "https://31z66kaiwh.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://dlib0mavh7.execute-api.eu-west-2.amazonaws.com",
  },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "festiverse",
    defaultDesktopRoom: "conferenceRoom",
  },
  landingPage: {
    backgroundVideo: "assets/videos/zonevs-bgvideo.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/zonevs-logo-welcome.svg",
    menuLogo: "assets/logos/zonevs-logo.png",
    menuLogoHref: "https://zonevs.io",
    links: [
      {
        label: "About",
        href: "https://zonevs.io/about",
      },
      {
        label: "Pricing",
        href: "https://zonevs.io/pricing",
      },
    ],
  },
};

const oasisdigitalgallery: Config = {
  auth0: {
    domain: "pestex.eu.auth0.com",
    clientId: "JFVVaGWPlQE6Go86pB9y9LuIHEWoNTKJ",
  },
  api: {
    private_url: "https://g5vavgrojl.execute-api.eu-west-2.amazonaws.com",
    public_url: "https://c32m2su6t1.execute-api.eu-west-2.amazonaws.com",
  },
  sentry: {
    enabled: true,
  },
  app: {
    defaultMobileRoom: "oasisdigitalgallerymobile",
    defaultDesktopRoom: "oasisdigitalgallery",
    disableZoneJump: true,
  },
  landingPage: {
    backgroundVideo: "assets/videos/oasis-bgvideo_02.mp4",
    backgroundVideoPoster: "zonevs_loading_fade.gif",
    heroLogo: "assets/logos/oasis-logo-welcome.svg",
    menuLogo: "assets/logos/oasis-logo.svg",
    menuLogoHref: "https://oasisdigitalgallery.zonevs.io",
    links: [
      {
        label: "Privacy",
        href: "https://oasisdigitalgallery.zonevs.io/oaprivacy",
      },
      {
        label: "About",
        href: "https://oasisdigitalgallery.zonevs.io/oaabout",
      },
      {
        label: "Contact",
        href: "https://oasisdigitalgallery.zonevs.io/oacontact",
      },
      {
        label: "Instructions",
        href: "https://oasisdigitalgallery.zonevs.io/oainstructions",
      },
    ],
  },
};

// Default to dev if not set
const config = (function () {
  console.log(`Config using '${process.env.REACT_APP_STAGE}' stage.`);
  switch (process.env.REACT_APP_STAGE) {
    case "oasisdigitalgallery":
      return oasisdigitalgallery;
    case "prod":
      return prod;
    case "local":
    case "dev":
      return dev;
    default:
      return dev;
  }
})();

export default {
  ...config,
};
