import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useMediaDevices } from "react-use";

export default function DialogMultiplayerSettings(props) {
    const { open, multiplayer } = props;

    const [videoDevice, setVideoDevice] = useState();
    const [audioDevice, setAudioDevice] = useState();

    const state = useMediaDevices();

    useEffect(() => {
        if (multiplayer.joined) {
            setVideoDevice(multiplayer.currentCamera);
            setAudioDevice(multiplayer.currentMicrophone);
        }
    }, [
        multiplayer.currentCamera,
        multiplayer.currentMicrophone,
        multiplayer.joined,
    ]);

    if (!videoDevice || !audioDevice || !state || !state.devices) {
        return null;
    }

    const videoDevices = state.devices.filter((x) => x.kind === "videoinput");
    const audioDevices = state.devices.filter((x) => x.kind === "audioinput");

    const handleChangeVideoInput = async (event) => {
        const id = event.target.value;
        await multiplayer.setCamera(id);
        setVideoDevice(id);
    };

    const handleChangeMicrophoneInput = async (event) => {
        const id = event.target.value;
        await multiplayer.setMicrophone(id);
        setAudioDevice(id);
    };

    const handleLeave = () => {
        multiplayer.leave();
        props.onClose();
    };

    return (
        <Dialog open={open} fullWidth {...props}>
            <DialogTitle>Settings</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            label="Webcam"
                            fullWidth
                            variant="outlined"
                            select
                            value={videoDevice}
                            onChange={handleChangeVideoInput}
                        >
                            {videoDevices.map((device) => (
                                <MenuItem
                                    key={device.deviceId}
                                    value={device.deviceId}
                                >
                                    {device.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Microphone"
                            variant="outlined"
                            fullWidth
                            select
                            value={audioDevice}
                            onChange={handleChangeMicrophoneInput}
                        >
                            {audioDevices.map((device) => (
                                <MenuItem
                                    key={device.deviceId}
                                    value={device.deviceId}
                                >
                                    {device.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            onClick={handleLeave}
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Leave Session
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
