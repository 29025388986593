import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export default function LogoutPage() {
  const { isLoading, logout } = useAuth0();

  useEffect(() => {
    if (!isLoading) {
      logout();
      // window.setTimeout(() => {
      //
      // })
      // window.open("/", "_self");
    }
  }, [isLoading, logout]);

  return <p>Logging out..</p>;
}
