import * as THREE from "three";
import Clickable from "./Clickable";
import Hoverable from "./Hoverable";
import Multiplayer from "./Multiplayer2";
// import UnlitImageMaterial from "./UnlitMaterial";
//import {RecordEvent} from "../libs/AnalyticsLib";

export default class ImageViewerNFTsimpleBio {
  width = 3.2;
  height = 1.8;
  currentIndex = 0;

  constructor(renderer, uuid, scene, items, width, height, hideControls) {
    this.renderer = renderer;
    this.uuid = uuid;
    this.scene = scene;
    this.items = items;
    this.width = width || 3.2;
    this.height = height || 1.8;
    Multiplayer.instance.registerCustomEventHandler(this.uuid, this);

    this.loader = new THREE.TextureLoader();

    const geometry = new THREE.PlaneBufferGeometry(this.width, this.height);

    const material = new THREE.MeshBasicMaterial({ color: "white" });

    const board = new THREE.Mesh(geometry, material);

    this.board = board;

    scene.add(this.board);

    if (!hideControls) {
      //todo - this needs to be optional - controlled by scene
    }

    if (this.items) this.setImage(this.items[0]);
  }

  setPosition = (x, y, z, a, b, c) => {
    this.board.position.set(x + a, y + b, z + c);
  };

  setRotation = (x, y, z) => {
    this.board.rotateX(x * THREE.Math.DEG2RAD);
    this.board.rotateY(y * THREE.Math.DEG2RAD);
    this.board.rotateZ(z * THREE.Math.DEG2RAD);
  };

  setImage(image, skipSend) {
    if (!skipSend) {
      Multiplayer.instance.sendCustomEvent(this.uuid, "setImageRemote", {
        index: this.items.findIndex((x) => x.id === image.id),
      });
    }

    this.loader.load(image.imageUrl, (texture) => {
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      texture.minFilter = THREE.LinearFilter;
      const material = new THREE.ShaderMaterial({
        fragmentShader: this.fragmentShader(),
        vertexShader: this.vertexShader(),
        uniforms: { tex: { type: "t", value: texture } },
      });

      this.board.material = material;
      this.board.material.needsUpdate = true;
    });
  }

  setImageRemote(params) {
    const image = this.items[params.index];
    this.setImage(image, true);
  }

  vertexShader() {
    return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `;
  }

  fragmentShader() {
    return `
            uniform sampler2D tex;
            varying vec2 vUv;

            void main() {
                gl_FragColor = texture2D(tex, vUv);
            }
        `;
  }
}
