import { Box, Button, Grid, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import AppBar from "./UIAppBar";
import queryString from "query-string";
import config from "../config";

export default function UIWelcomeScreen(props) {
  // const [buttonLabel, setButtonLabel] = useState("GET STARTED - HEADPHONES ESSENTIAL");
  const [buttonLabel, setButtonLabel] = useState("GET STARTED");

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.invitation) {
      // setButtonLabel("JOIN MEETING - HEADPHONES ESSENTIAL");
      setButtonLabel("JOIN MEETING");
    }
  }, []);

  return (
    <div>
      <Box
        // onClick={props.onInteracted}
        width="100%"
        height="100%"
        top={0}
        position="absolute"
        bgcolor="#ffffff"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {/*is user VISITOR or ATTENDEE or HOST?*/}

        {/*if VISITOR goto WELCOME SCREEN*/}
        {/*if ATTENDEE OR HOST goto LOADING SCREEN*/}

        {/*=PRELOADER============================================================*/}

        {/*=WELCOME SCREEN============================================================*/}

        {/*user clicks "get started"*/}

        {/*=LOBBY POD in 3D============================================================*/}

        {/*fade in loading or... fade out welcome screen screen to reveal  three.js lobby pod*/}
        {/*{ULTRA SMOOTH & SIMPLE - Lobby scene immediately loads in bg}*/}
        {/*lobby pod fades out / pops away... */}

        {/*=THREE JS ENGINE============================================================*/}
        <div id="home-top-video">
          <video
            className="App-bgvideo"
            playsInline
            loop
            muted
            autoPlay
            poster={config.landingPage.backgroundVideoPoster}
            id="bgvid"
          >
            <source src={config.landingPage.backgroundVideo} type="video/mp4" />
            {/*<source src="assets/videos/null.webm" type="video/webm">*/}
            {/*<source src="assets/videos/null.mp4" type="video/ogg">*/}
            Your browser does not support the video tag.
          </video>
          {/*<div id="home-text">*/}
          {/*    <div><img src="http://marquesslondon.herokuapp.com/images/logo.ee1689ee.png"></div>*/}
          {/*    <h3>LIFESTYLE</h3>*/}
          {/*</div>*/}
        </div>

        {/*its a good practice to show that background video only on larger devices.*/}
        {/*Because on mobile phones background video may take up too many system  resources and effects the performance*/}
        {/*So,add a media query and set display:block for mobile devices.*/}

        {/*todo - we need a font preloader to remove font-flash*/}
        <div className="viewport-header">
          <Grid container>
            <Grid container>
              <Grid item xs={1} align="center">
                {/*_______________________*/}
                <img src="assets/images/transparent.png" alt={""} />
              </Grid>

              <Grid item xs={10} align="center">
                <img src={config.landingPage.heroLogo} alt={""} />
              </Grid>

              <Grid item xs={1} align="center">
                <img src="assets/images/transparent.png" alt={""} />
              </Grid>
            </Grid>

            {/*<Box py={5.0}> </Box>*/}

            {/*<Grid align="center">*/}
            {/*    <img src="assets/images/transparent_2.png" alt={""} />*/}
            {/*</Grid>*/}

            {/*//todo - where are the themes stored?? - can we align button text*/}
            <Grid container>
              <Grid item xs={1}></Grid>
              <Grid item xs={10} className="custom-text-Barlow-Regular-small-upper" align="center">
                {/*<Button style={{position: 'absolute',top: 10,left: -10, padding: "11px 0px" }}*/}
                {/*        variant="contained" fullWidth color='primary'   >GET STARTED</Button>*/}

                <Button
                  style={{ padding: "11px 0px" }}
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={props.onInteracted}
                >
                  {buttonLabel}
                </Button>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Grid>
        </div>

        <Stack>
          {/*<img src="assets/images/zonevs_loading_fade.gif" />*/}
          {/*<Typography align="center">click anywhere to begin</Typography>*/}
        </Stack>
      </Box>
      <AppBar />
    </div>
  );
}
