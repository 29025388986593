import {
  // Button,
  Dialog,
  // DialogTitle,
  // DialogActions,
  Box,
  Grid,
  // Link,
  DialogContent,
  Link,
  // useTheme,
  // useMediaQuery
} from "@mui/material";
// import React, { useEffect } from "react";
//import {RecordEvent} from "../libs/AnalyticsLib";
// import Link from "@material-ui/core/Link";
// import Fab from '@material-ui/core/Fab';
// import AddIcon from '@material-ui/icons/Add';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@mui/material/IconButton";
// import Paper from '@material-ui/core/Paper';

// https://www.webfx.com/blog/web-design/examples-of-modal-windows/

export default function ModalOAAbout(props) {
  const { open, onClose } = props;
  // const theme = useTheme();
  // const fullscreenRecommended = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      open={open}
      fullWidth //for mobile modal
      // fullScreen={fullscreenRecommended} //for mobile fullscreen
      // maxWidth="sm" //for mobile fullscreen

      // REF
      // xs, extra-small: 0px
      // sm, small: 600px
      // md, medium: 960px
      // lg, large: 1280px
      // xl, extra-large: 1920px

      sx={{
        "& .MuiDialog-paper": {
          // backgroundColor: "red",
          // backgroundColor: 'rgba(17,20,49,1.0)',
          backgroundColor: "rgb(17,20,49)",
          // backgroundImage: "url(" + "assets/images/banners/banner.jpg" + ")",
          // backgroundPosition: 'center',
          // backgroundSize: 'cover',
          // backgroundRepeat: 'no-repeat'
        },
      }}
    >
      <DialogContent
        sx={{
          maxWidth: "100vw",
          position: "relative",
        }}
      >
        <IconButton
          onClick={() => onClose()}
          sx={{
            color: "white",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box py={1.0}></Box>
        <img src="assets/logos/zonevs-logo.svg" alt={""} width="100%" />

        <Grid container>
          {/*<Grid item xs={3}>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <div className="custom-text-Barlow-Regular-dialogBoxHeadline" align="left" justify="left">
              <Box py={1.0}></Box>
              About
            </div>
            <div className="custom-text-Barlow-Regular-dialogBox-small" align="left" justify="left">
              <Box py={1.0}></Box>
              Who Are We?
              {/*<Box py={1.0}></Box>*/}
              {/*Oasis Digital Studios is a technology and services agency that has brought together leading individuals*/}
              {/*and organizations in blockchain technology, computer graphics, Augmented Reality, entertainment, art,*/}
              {/*sports, gaming, music, media, comic book, memorabilia, and pop culture arenas to support the fast-paced*/}
              {/*emergence of the NFT marketplace.*/}
              {/*<Box py={1.0}></Box>*/}
              {/*With opportunities already in process, the Oasis model is to create an ongoing partnership with its*/}
              {/*Artists and share in the ongoing revenue of the initial sale and any residual sales creating potential*/}
              {/*royalty-type revenue relationships. The first Artist offering is expected to launch its first program in*/}
              {/*early April 2021, with initial program offerings consisting of digital artwork, with the expected*/}
              {/*expansion to both digital artwork, trading cards, limited editions and series, and physical product*/}
              {/*programs.*/}
              {/*<Box py={1.0}></Box>*/}
              {/*<Box py={1.0}></Box>*/}
              {/*<Link*/}
              {/*  style={{ color: "#7cc9fd" }}*/}
              {/*  href="https://oasisdigitalstudios.com/"*/}
              {/*  underline="always"*/}
              {/*  target="_blank"*/}
              {/*>*/}
              {/*  <Box py={0.5}></Box>*/}
              {/*  https://oasisdigitalstudios.com/*/}
              {/*</Link>*/}
              <Box py={1.0}></Box>
              For more information about our gallery click:_
              <Link
                style={{ color: "#7cc9fd" }}
                href="https://oasisdigitalstudios.com/gallery/"
                underline="always"
                target="_blank"
              >
                here
              </Link>
              <Box py={2.0}></Box>
            </div>
          </Grid>
          {/*<Grid item xs={3}>*/}
          {/*</Grid>*/}
        </Grid>

        {/*<img src="assets/images/oasisBarcodePonies/Oasis-Logo-v1.png" alt={""} width="100%" />*/}
      </DialogContent>
    </Dialog>
  );
}
