import * as THREE from "three";
import Clickable from "./Clickable";
import Hoverable from "./Hoverable";
import Multiplayer from "./Multiplayer2";
// import UnlitImageMaterial from "./UnlitMaterial";
//import {RecordEvent} from "../libs/AnalyticsLib";

export default class ImageViewerNFTsimple {
  width = 3.2;
  height = 1.8;
  currentIndex = 0;

  constructor(renderer, uuid, scene, items, width, height, hideControls) {
    this.renderer = renderer;
    this.uuid = uuid;
    this.scene = scene;
    this.items = items;
    this.width = width || 3.2;
    this.height = height || 1.8;
    Multiplayer.instance.registerCustomEventHandler(this.uuid, this);

    const buttonSize = 0.25;

    this.loader = new THREE.TextureLoader();
    this.loader2 = new THREE.TextureLoader();

    const geometry = new THREE.PlaneBufferGeometry(this.width, this.height);
    const geometry2 = new THREE.PlaneBufferGeometry(this.width, this.width * 0.133);
    const material = new THREE.MeshBasicMaterial({ color: "white" });
    const material2 = new THREE.MeshBasicMaterial({ color: "white" });

    const board = new THREE.Mesh(geometry, material);
    const board2 = new THREE.Mesh(geometry2, material2);
    this.board = board;
    this.board2 = board2;
    scene.add(this.board);
    scene.add(this.board2);

    if (!hideControls) {
      //todo - this needs to be optional - controlled by scene
      this.loader.load("assets/images/oasisBarcodePonies/buy.jpg", (texture) => {
        // const material = new THREE.MeshBasicMaterial({
        //   map: texture,
        // });

        const material = new THREE.ShaderMaterial({
          fragmentShader: this.fragmentShader(),
          vertexShader: this.vertexShader(),
          uniforms: { tex: { type: "t", value: texture } },
        });

        const geometry = new THREE.PlaneBufferGeometry(buttonSize * 1.05, buttonSize * 1.05);
        // const geometry = new THREE.CircleGeometry(buttonSize / 1, 32);
        const control = new THREE.Mesh(geometry, material);

        // control.position.x = -this.width / 2 - buttonSize / 2 - 0.1;
        control.position.z = -0.03;
        control.position.y = -this.height / 2 - buttonSize / 1 - 0.094;
        this.board.add(control);
        new Clickable(control, this.buy);

        const showHelp = () => {
          document.getElementById("help-text").style.display = "block";
          document.getElementById("help-text").innerHTML = "Click here to buy this NFT";
        };

        const hideHelp = () => (document.getElementById("help-text").style.display = "none");

        new Hoverable(
          control,
          () => {
            document.body.style.cursor = "pointer";
            showHelp();
            control.scale.setScalar(1.2);
          },
          () => {
            control.scale.setScalar(1);
            hideHelp();
          }
        );
      });
    }

    if (this.items) this.setImage(this.items[0]);
  }

  setRotation = (x, y, z) => {
    this.board.rotateX(x * THREE.Math.DEG2RAD);
    this.board.rotateY(y * THREE.Math.DEG2RAD);
    this.board.rotateZ(z * THREE.Math.DEG2RAD);
    this.board2.rotateX(x * THREE.Math.DEG2RAD);
    this.board2.rotateY(y * THREE.Math.DEG2RAD);
    this.board2.rotateZ(z * THREE.Math.DEG2RAD);
  };

  setPosition = (x, y, z, a, b, c) => {
    this.board.position.set(x + a, y + b, z + c);
    this.board2.position.set(x + a, y + b + 0.7, z + c);
  };

  setImage(image, skipSend) {
    if (!skipSend) {
      Multiplayer.instance.sendCustomEvent(this.uuid, "setImageRemote", {
        index: this.items.findIndex((x) => x.id === image.id),
      });
    }

    this.loader.load(image.imageUrl, (texture) => {
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      texture.minFilter = THREE.LinearFilter;
      const material = new THREE.ShaderMaterial({
        fragmentShader: this.fragmentShader(),
        vertexShader: this.vertexShader(),
        uniforms: { tex: { type: "t", value: texture } },
      });

      this.board.material = material;
      this.board.material.needsUpdate = true;
    });

    this.loader2.load(image.imageLabelUrl, (texture) => {
      texture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
      texture.minFilter = THREE.LinearFilter;
      const material2 = new THREE.ShaderMaterial({
        fragmentShader: this.fragmentShader(),
        vertexShader: this.vertexShader(),
        uniforms: { tex: { type: "t", value: texture } },
      });

      this.board2.material = material2;
      this.board2.material.needsUpdate = true;
    });
  }

  setImageRemote(params) {
    const image = this.items[params.index];
    this.setImage(image, true);
  }

  next = () => {
    if (!this.items) return;
    // if(this.currentIndex === this.images.length - 1) return;
    if (this.currentIndex === this.items.length - 1) this.currentIndex = -1;
    this.currentIndex++;
    this.setImage(this.items[this.currentIndex]);
  };

  previous = () => {
    if (!this.items) return;
    // if(this.currentIndex === 0) return;
    if (this.currentIndex === 0) this.currentIndex = this.items.length;
    this.currentIndex--;
    this.setImage(this.items[this.currentIndex]);
  };

  buy = () => {
    const image = this.items[this.currentIndex];
    // window.open("https://share.hsforms.com/1eFdFUkz3SeG9Ny9ywMDKfg4rqa5", "_blank");
    window.open(image.shopUrl, "_blank");
    console.log(`cha-ching!!`);
  };

  vertexShader() {
    return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `;
  }

  fragmentShader() {
    return `
            uniform sampler2D tex;
            varying vec2 vUv;

            void main() {
                gl_FragColor = texture2D(tex, vUv);
            }
        `;
  }
}
