import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
// import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
// import { Dialog } from "@mui/material";
// import ModalImage from "./ModalImage";
import ModalAbout from "./modalAbout";
import ModalPricing from "./modalPricing";
import ModalContact from "./modalContact";
import config from "../config";
import { Link } from "@mui/material";
// these are from the docs - no longer used
// const pages = ["Home", "Pricing", "Contact"];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  // const handleOpenUserMenu = (event) =>
  // {
  //     setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
      setAnchorElNav(null);
  };

  // const handleCloseUserMenu = () =>
  // {
  //     setAnchorElUser(null);
  // };

  return (
    <>
      <AppBar position="absolute" color="transparent" elevation={0}>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ color: "white" }}
              >
                <MenuIcon />
              </IconButton>
              <a href={config.landingPage.menuLogoHref}>
                <Box
                  component="img"
                  sx={{
                    height: 48,
                    mx: 2,
                  }}
                  alt="Your logo."
                  src={config.landingPage.menuLogo}
                  //needs to be a link
                />
              </a>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{ display: { xs: "block", md: "none" } }}

                //need to add paper to mobile menu
                // sx={{display: { xs: "block", md: "none"},
                //     "& .MuiDialog-paper": {
                //         // backgroundColor: "red",
                //         // backgroundColor: 'rgba(17,20,49,1.0)',
                //         // backgroundColor: 'rgb(17,20,49)',
                //         // backgroundImage: "url(" + "assets/images/banners/banner.jpg" + ")",
                //         // backgroundPosition: 'center',
                //         // backgroundSize: 'cover',
                //         // backgroundRepeat: 'no-repeat'
                //     }
                // }}
              >
                {config.landingPage.links.map((link) => (
                  <MenuItem key={link.label} href={link.href} component="a">
                    <Typography textAlign="center">{link.label}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            {/*<Typography*/}
            {/*    variant="h6"*/}
            {/*    noWrap*/}
            {/*    component="div"*/}
            {/*    sx={{ color: "primary", flexGrow: 1, display: { xs: "flex", md: "none" } }}*/}
            {/*>*/}
            {/*  MUI SPECIFIED MOBILE LOGO*/}
            {/*</Typography>*/}

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <a href={config.landingPage.menuLogoHref}>
                <Box
                  component="img"
                  sx={{
                    height: 48,
                    mx: 2,
                  }}
                  alt="site logo"
                  src={config.landingPage.menuLogo}
                  // consider link to tld
                />
              </a>
              {config.landingPage.links.map((link) => (
                <Button key={link.label} href={link.href} sx={{ my: 2, color: "white", display: "block" }}>
                  {link.label}
                </Button>
              ))}
            </Box>

            {/*ACCOUNT / AVATAR MENU*/}
            {/*<Box sx={{ flexGrow: 0 }}>*/}
            {/*    <Tooltip title="Open settings">*/}
            {/*        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>*/}
            {/*            <Avatar alt="My ZoneVS Account" src="/assets/icons/avatar.svg" />*/}
            {/*        </IconButton>*/}
            {/*    </Tooltip>*/}
            {/*    <Menu*/}
            {/*        sx={{ mt: "45px" }}*/}
            {/*        id="menu-appbar"*/}
            {/*        anchorEl={anchorElUser}*/}
            {/*        anchorOrigin={{*/}
            {/*            vertical: "top",*/}
            {/*            horizontal: "right"*/}
            {/*        }}*/}
            {/*        keepMounted*/}
            {/*        transformOrigin={{*/}
            {/*            vertical: "top",*/}
            {/*            horizontal: "right"*/}
            {/*        }}*/}
            {/*        open={Boolean(anchorElUser)}*/}
            {/*        onClose={handleCloseUserMenu}*/}
            {/*    >*/}
            {/*        {settings.map((setting) => (*/}
            {/*            <MenuItem key={setting} onClick={handleCloseNavMenu}>*/}
            {/*                <Typography textAlign="center">{setting}</Typography>*/}
            {/*            </MenuItem>*/}
            {/*        ))}*/}
            {/*    </Menu>*/}
            {/*</Box>*/}
          </Toolbar>
        </Container>
      </AppBar>
      <ModalAbout
        open={aboutModalOpen}
        onClose={() => setAboutModalOpen(false)}
        src="assets/images/banners/banner.jpg"
      />
      <ModalPricing open={pricingModalOpen} onClose={() => setPricingModalOpen(false)} src="" />
      <ModalContact open={contactModalOpen} onClose={() => setContactModalOpen(false)} src="" />
    </>
  );
};

export default ResponsiveAppBar;
