import { Button, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import tawkTo from "tawkto-react";

export default function DialogCookieConsent(props){

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const value = localStorage.getItem("cookie-consent");
        if(!value){
            setOpen(true);
        }else {
            initialise(value);
        }
    }, [])

    const initialise = (value) => {
        if(value === "accepted"){
            
            try {
                // Start Mouseflow
                window.mouseflow.start();

                // Start Tawkto
                const tawkToPropertyId = '617fac9bf7c0440a5920e5cd';
                const tawkToKey = '1fjdbkfpo'
                tawkTo(tawkToPropertyId, tawkToKey)
            }catch(e){
                console.error(e);
                console.log("The error above occurred when attempting to init cookies");
            }
        }
    }

    const handleAccept = () => {
        setOpen(false);
        localStorage.setItem("cookie-consent", "accepted");
        initialise("accepted");
    }

    const handleDecline = () => {
        setOpen(false);
        localStorage.setItem("cookie-consent", "declined");
    }

    if(!open)
        return null;

    return (
        <Paper sx={{
            p: 2,
            px: 4,
            bottom: 10,
            left: '50%',
            transform: "translate(-50%)",
            position: "absolute",
            zIndex: 1000
        }}>
            <Stack direction='column' spacing={1}>
                <Typography variant="body2">We use cookies for chat support and to understand visitor behavior.</Typography>
                <Typography variant="body2">We do not use tracking cookies, you can opt-out if you wish.</Typography>
                <Stack direction='row-reverse' spacing={2}>
                    <Button size="small" variant="contained" onClick={handleDecline}>Decline cookies</Button>
                    <Button size="small" variant="contained" color="success" onClick={handleAccept}>Accept cookies</Button>
                </Stack>
            </Stack>
        </Paper>
    )
}
