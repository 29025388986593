import { Box, Container, Hidden, IconButton, Paper } from "@mui/material";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SettingsIcon from "@mui/icons-material/Settings";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Screenshot from "../three-components/Screenshot";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import PhoneEnabled from "@mui/icons-material/PhoneEnabled";
import PhoneDisabled from "@mui/icons-material/PhoneDisabled";
import VolumeMute from "@mui/icons-material/VolumeMute";
import VolumeOff from "@mui/icons-material/VolumeOff";
import Videocam from "@mui/icons-material/Videocam";
import VideocamOff from "@mui/icons-material/VideocamOff";

import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";

// import React, { useEffect, useState } from "react";
import React, { useState } from "react";
import { observer } from "mobx-react";
import MovePad from "../three-components/MovePad";
import * as THREE from "three";
import DialogMultiplayerSettings from "./DialogMultiplayerSettings";
// import App from "../core/App";

const UIToolbar2 = observer((props) => {
  const { engine, onFullScreen } = props;
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { controls, multiplayer } = engine;

  const handleLeave = () => {
    multiplayer.leave();
  };

  const handleToggleVideo = () => {
    if (multiplayer.video_muted) {
      multiplayer.unmuteVideo();
    } else {
      multiplayer.muteVideo();
    }
  };

  const handleToggleAudio = () => {
    if (multiplayer.audio_muted) {
      multiplayer.unmuteAudio();
    } else {
      multiplayer.muteAudio();
    }
  };

  const handleAudioOff = () => {};

  // does not work - we might need to reload scene to do this - resize is not great on mobile
  // useEffect(() => {
  //     if(App.isMobile){
  //         window.setTimeout(() => {
  //             const text = document.getElementById("help-text");
  //             if(!text) return;
  //             text.innerText = "Rotate your device to landscape";
  //             text.style.display = "block";
  //             window.setTimeout(() => {
  //                 text.style.display = "none";
  //             }, 10000)
  //         }, 2000)
  //     }
  // }, [])

  const handleSkipPrev = () => {
    const index = MovePad.heroPads.indexOf(MovePad.getCurrentPad());
    if (index === 0) {
      const nextPad = MovePad.heroPads[MovePad.heroPads.length - 1];
      MovePad.currentPad = nextPad;
      controls.moveTo(new THREE.Vector3(nextPad.position.x, nextPad.position.y + 1.6, nextPad.position.z));
    } else {
      const nextPad = MovePad.heroPads[index - 1];
      MovePad.currentPad = nextPad;
      if (nextPad.hero === true) {
        console.log(`DEBUG moving directly to a HERO pad = ${nextPad.hero}`);
        controls.moveTo(new THREE.Vector3(nextPad.position.x, nextPad.position.y + 1.6, nextPad.position.z));
      } else if (nextPad.hero === false) {
        console.log(`DEBUG skip through basic pads and move to next HERO pad = ${nextPad.hero}`);
        console.log(`DEBUG current pad index is = ${index}`);
        // const skipToNextPad = MovePad.pads[index - 1];
        // MovePad.currentPad = skipToNextPad;
        // controls.moveTo(new THREE.Vector3(skipToNextPad.position.x, skipToNextPad.position.y + 1.6, skipToNextPad.position.z));
      }
    }
  };

  const handleSkipNext = () => {
    const index = MovePad.heroPads.indexOf(MovePad.currentPad);
    if (index === MovePad.heroPads.length - 1) {
      const nextPad = MovePad.heroPads[0];
      MovePad.currentPad = nextPad;
      controls.moveTo(new THREE.Vector3(nextPad.position.x, nextPad.position.y + 1.6, nextPad.position.z));
    } else {
      const nextPad = MovePad.heroPads[index + 1];
      MovePad.currentPad = nextPad;
      if (nextPad.hero === true) {
        console.log(`DEBUG moving directly to a HERO pad = ${nextPad.hero}`);
        controls.moveTo(new THREE.Vector3(nextPad.position.x, nextPad.position.y + 1.6, nextPad.position.z));
      } else if (nextPad.hero === false) {
        console.log(`DEBUG skip through basic pads and move to next HERO pad = ${nextPad.hero}`);
        console.log(`DEBUG current pad index is = ${index}`);
        // const skipToNextPad = MovePad.pads[index + 1];
        // MovePad.currentPad = skipToNextPad;
        // controls.moveTo(new THREE.Vector3(skipToNextPad.position.x, skipToNextPad.position.y + 1.6, skipToNextPad.position.z));
      }
    }
  };

  return (
    <>
      <Box
        position="absolute"
        bottom="2%"
        left="50%"
        sx={{
          transform: "translate(-50%)",
        }}
      >
        <div
          id="help-text"
          style={{
            display: "none",
            background: "rgba(0, 0, 0, 0.3)",
            color: "white",
            width: "100%",
            marginBottom: "20px",
            padding: "8px 12px",
            borderRadius: "4px",
            textAlign: "center",
          }}
        >
          Help text goes here
        </div>
        <Box component={Paper} p={0.5} display="flex" alignItems="center" justifyContent="center">
          {/*<Hidden mdDown>*/}
          {/*    <IconButton onClick={handleSkipPrev} size="large" color="primary">*/}
          {/*        <SkipPreviousIcon />*/}
          {/*    </IconButton>*/}
          {/*</Hidden>*/}
          <Box flexGrow={1} />
          <IconButton onClick={controls.resetPosition} size="large">
            <HomeIcon />
          </IconButton>
          <IconButton onClick={controls.jumpLeft} size="large">
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton onClick={controls.jumpRight} size="large">
            <KeyboardArrowRightIcon />
          </IconButton>
          <IconButton onClick={controls.jumpForward} size="large">
            <KeyboardArrowUpIcon />
          </IconButton>
          <IconButton onClick={controls.jumpBackward} size="large">
            <KeyboardArrowDownIcon />
          </IconButton>
          <Box flexGrow={1} />
          <Hidden mdDown>
            <IconButton onClick={onFullScreen} size="large">
              <FullscreenIcon />
            </IconButton>
            {/*<IconButton onClick={() => Screenshot.takeScreenshot()} size="large">*/}
            {/*  <PhotoCameraIcon />*/}
            {/*</IconButton>*/}
            {engine.multiplayer.joined && (
              <>
                <IconButton size="large" disabled={!engine.multiplayer.joined} onClick={() => setSettingsOpen(true)}>
                  <SettingsIcon />
                </IconButton>
                <IconButton onClick={multiplayer.startScreenShare} size="large" disabled={!engine.multiplayer.joined}>
                  <ScreenShareIcon />
                </IconButton>
                <IconButton onClick={handleToggleAudio} size="large">
                  {multiplayer.audio_muted ? <MicOffIcon /> : <MicIcon />}
                </IconButton>
                <IconButton onClick={handleToggleVideo} size="large">
                  {multiplayer.video_muted ? <VideocamOff /> : <Videocam />}
                </IconButton>
              </>
            )}
          </Hidden>
          {engine.multiplayer.joined && (
            <IconButton onClick={handleLeave} size="large" disabled={!engine.multiplayer.joined}>
              <PhoneDisabled />
            </IconButton>
          )}

          {/*<IconButton onClick={handleSkipNext} size="large" color="primary">*/}
          {/*  <SkipNextIcon />*/}
          {/*</IconButton>*/}
        </Box>
      </Box>
      <DialogMultiplayerSettings
        open={settingsOpen}
        multiplayer={engine.multiplayer}
        onClose={() => setSettingsOpen(false)}
      />
    </>
  );
});

export default UIToolbar2;
