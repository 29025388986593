import * as THREE from "three";
import Clickable from "./Clickable";
import Multiplayer from "./Multiplayer2";
import Hoverable from "./Hoverable";
// import { PositionalAudioHelper } from "three/examples/jsm/helpers/PositionalAudioHelper";
// import { RAYCAST_EXCLUDE_LAYER } from "../core/Scene";

export default class VideoViewerGreenscreen {
  static viewers = [];
  currentIndex = 0;

  constructor(uuid, scene, listener, poster, width, height, controloffset, controlpushforward, beginpaused) {
    this.uuid = uuid;
    this.scene = scene;
    this.width = width || 2.8;
    this.height = height || 1.55;
    this.controloffset = controloffset || 0.0;
    this.controlpushforward = controlpushforward || 0.0;
    this.beginpaused = beginpaused || 1.0;

    Multiplayer.instance.registerCustomEventHandler(this.uuid, this);
    VideoViewerGreenscreen.viewers.push(this);

    const buttonSize = 0.2;

    this.loader = new THREE.TextureLoader();

    const geometry = new THREE.PlaneBufferGeometry(this.width, this.height);

    const board = new THREE.Mesh(geometry);
    this.board = board;

    const video = document.getElementById(uuid);
    this.video = video;

    const positionalAudioGreenscreen = new THREE.PositionalAudio(listener);
    positionalAudioGreenscreen.play();
    positionalAudioGreenscreen.setMediaElementSource(video);
    positionalAudioGreenscreen.setRefDistance(6);
    positionalAudioGreenscreen.setMaxDistance(7);
    positionalAudioGreenscreen.setVolume(0.8);
    positionalAudioGreenscreen.setRolloffFactor(100);
    positionalAudioGreenscreen.setDirectionalCone(180, 230, 0.1);
    this.positionalAudio = positionalAudioGreenscreen;

    board.add(positionalAudioGreenscreen);

    // const helper = new PositionalAudioHelper(positionalAudio, 2);
    // helper.layers.disableAll();
    // helper.layers.enable(RAYCAST_EXCLUDE_LAYER);
    // positionalAudio.add(helper);

    scene.add(this.board);

    this.loader.load("assets/icons/ZoneVS-pause.png", (texture) => {
      const material = new THREE.MeshBasicMaterial({
        map: texture,
      });
      const geometry = new THREE.CircleGeometry(buttonSize * 0.5, 32);
      const controlA = new THREE.Mesh(geometry, material);
      controlA.position.x = 0;
      controlA.position.y = this.height / 1.9 - buttonSize / 2 + 0.12;
      controlA.position.z = -(-0.025 + controlpushforward);
      this.board.add(controlA);
      new Clickable(controlA, () => this.hide());
      this.pauseButton = controlA;
      this.pauseButton.visible = true;
      if (beginpaused) {
        this.pauseButton.visible = false;
      }
      const showHelp = () => {
        document.getElementById("help-text").style.display = "block";
        // document.getElementById("help-text").innerHTML = "Click here to pause this performance";
        document.getElementById("help-text").innerHTML = "Click here to pause";
      };
      const hideHelp = () => (document.getElementById("help-text").style.display = "none");
      new Hoverable(
        this.pauseButton,
        () => {
          document.body.style.cursor = "pointer";
          this.pauseButton.scale.set(1.1, 1.1, 1.1);
          showHelp();
        },
        () => {
          this.pauseButton.scale.set(1.0, 1.0, 1.0);
          hideHelp();
        }
      );
    });

    this.loader.load("assets/icons/ZoneVS-play.png", (texture) => {
      const material = new THREE.MeshBasicMaterial({
        map: texture,
      });
      // const geometry = new THREE.PlaneBufferGeometry(buttonSize, buttonSize);
      const geometry = new THREE.CircleGeometry(buttonSize / 2, 32);
      const controlB = new THREE.Mesh(geometry, material);
      // controlB.position.x = -(this.width / 2) - (buttonSize / 2);
      controlB.position.x = 0.2;
      controlB.position.y = this.height / 1.9 - buttonSize / 2 + 0.12;

      controlB.position.z = -(-0.025 + controlpushforward);
      this.board.add(controlB);
      new Clickable(controlB, () => this.show());
      this.playButton = controlB;
      this.playButton.visible = false;
      if (beginpaused) {
        this.playButton.visible = true;
      }

      const showHelp = () => {
        document.getElementById("help-text").style.display = "block";
        document.getElementById("help-text").innerHTML = "Click here to play";
      };
      const hideHelp = () => (document.getElementById("help-text").style.display = "none");

      new Hoverable(
        this.playButton,
        () => {
          document.body.style.cursor = "pointer";
          this.playButton.scale.set(1.1, 1.1, 1.1);
          showHelp();
        },
        () => {
          this.playButton.scale.set(1.0, 1.0, 1.0);
          hideHelp();
        }
      );
    });

    this.loader.load("assets/icons/mute.png", (texture) => {
      const material = new THREE.MeshBasicMaterial({
        map: texture,
      });
      const geometry = new THREE.CircleGeometry(buttonSize * 0.5, 32);
      const controlC = new THREE.Mesh(geometry, material);
      controlC.position.x = -(this.width / 1.9) - buttonSize / 2;
      controlC.position.y = this.height / 1.9 - buttonSize / 2 + 0.12 + controloffset;
      controlC.position.z = -(-0.025);
      this.board.add(controlC);
      new Clickable(controlC, () => this.mute());
      this.muteButton = controlC;
      this.muteButton.visible = true;

      const showHelp = () => {
        document.getElementById("help-text").style.display = "block";
        document.getElementById("help-text").innerHTML = "Click here to mute";
      };
      const hideHelp = () => (document.getElementById("help-text").style.display = "none");
      new Hoverable(
        this.muteButton,
        () => {
          document.body.style.cursor = "pointer";
          this.muteButton.scale.set(1.1, 1.1, 1.1);
          showHelp();
        },
        () => {
          this.muteButton.scale.set(1.0, 1.0, 1.0);
          hideHelp();
        }
      );
    });

    this.loader.load("assets/icons/unmute.png", (texture) => {
      const material = new THREE.MeshBasicMaterial({
        map: texture,
      });
      const geometry = new THREE.CircleGeometry(buttonSize * 0.5, 32);
      const controlD = new THREE.Mesh(geometry, material);
      controlD.position.x = -(this.width / 1.9) - buttonSize / 2 + 0.2;
      controlD.position.y = this.height / 1.9 - buttonSize / 2 + 0.12;
      controlD.position.z = -(-0.025);
      this.board.add(controlD);
      new Clickable(controlD, () => this.unmute());
      this.unmuteButton = controlD;
      this.unmuteButton.visible = false;

      const showHelp = () => {
        document.getElementById("help-text").style.display = "block";
        document.getElementById("help-text").innerHTML = "Click here to unmute";
      };
      const hideHelp = () => (document.getElementById("help-text").style.display = "none");
      new Hoverable(
        this.unmuteButton,
        () => {
          document.body.style.cursor = "pointer";
          this.unmuteButton.scale.set(1.1, 1.1, 1.1);
          showHelp();
        },
        () => {
          this.unmuteButton.scale.set(1.0, 1.0, 1.0);
          hideHelp();
        }
      );
    });

    const texture = new THREE.VideoTexture(video);
    this.videoMaterial = new THREE.ShaderMaterial({
      fragmentShader: this.fragmentShader(),
      vertexShader: this.vertexShader(),
      uniforms: {
        tex: { type: "t", value: texture },
        //made true by default from start
        enabled: { value: true },
      },
      transparent: true,
    });
    this.board.material = this.videoMaterial;

    window.toggleGreenScreen = () => {
      //todo - why this does not work here? - we will need it at some point
      const enabled = !texture.uniforms.enabled.value;
      this.toggleGreenScreen(enabled, true);
      console.log(`toggleGreenScreen toggled inside Greenscreen Video`);
    };

    window.hideVideoViewerGreenscreen = () => {
      //todo - these two should be a toggle really
      VideoViewerGreenscreen.viewers.forEach((viewer) => {
        viewer.pause();
      });
      console.log("hideVideoViewerGreenscreen shown");
    };
    window.showVideoViewerGreenscreen = () => {
      VideoViewerGreenscreen.viewers.forEach((viewer) => {
        viewer.play();
      });
      console.log("hideVideoViewerGreenscreen hidden");
    };

    this.loader.load(poster, (texture) => {
      //todo - turned off - we might need it back at some point
      //const material = new THREE.MeshBasicMaterial({
      //map: texture
      //});
      // const material = new THREE.ShaderMaterial({
      //     fragmentShader: this.fragmentShader(),
      //     vertexShader: this.vertexShader(),
      //     uniforms: { tex: { type: "t", value: texture } },
      // });
      //turned off because we go straight to autoplay
      // this.posterMaterial = material;
      // this.board.material = material;
      // this.board.material.needsUpdate = true;
    });
  }

  setPosition = (x, y, z, a, b) => {
    this.board.position.set(x, y, z);
    //todo - added to get "Autoplay" - but will it sync up for all users?
    //need to use the proper play function below - but does not work
    // this.play();

    this.video.play();

    if (a == 1.0) {
      this.video.pause();
    }

    this.positionalAudio.setVolume(0.8);
  };

  setRotation = (x, y, z) => {
    //todo - needs full rotation just in case
    this.board.rotateX(x * THREE.Math.DEG2RAD);
    this.board.rotateY(y * THREE.Math.DEG2RAD);
    this.board.rotateZ(z * THREE.Math.DEG2RAD);
  };

  play = (skipSend) => {
    if (!skipSend) {
      // console.log("Sending custom event 1");
      Multiplayer.instance.sendCustomEvent(this.uuid, "play", true);
    }

    VideoViewerGreenscreen.viewers.forEach((video) => {
      //todo - needs to work in order to hide ALL vids???
      // video.play(true);
      // video.pause(true);
      // do the same for boards % positional audio
    });

    this.board.material = this.videoMaterial;
    this.video.play();
    this.positionalAudio.play();
    this.board.visible = true;

    // RecordEvent({
    //     type: "video-play",
    //     id: this.id
    // })
  };

  pause = (skipSend) => {
    if (!skipSend) {
      Multiplayer.instance.sendCustomEvent(this.uuid, "pause", true);
    }

    VideoViewerGreenscreen.viewers.forEach((video) => {
      //todo - needs to work in order to hide ALL vids???
      // video.play(true);
      // video.pause(true);
      // do the same for boards % positional audio
    });

    this.video.pause();
    // this.playButton.visible = true;
    // this.pauseButton.visible = false;
    this.positionalAudio.pause();
    this.board.visible = false;

    // if(!internal){
    //     RecordEvent({
    //         type: "video-pause",
    //         id: this.id
    //     })
    // }
  };

  mute = (skipSend) => {
    if (!skipSend) {
      Multiplayer.instance.sendCustomEvent(this.uuid, "mute", true);
    }
    this.positionalAudio.setVolume(0.0);
    this.muteButton.visible = false;
    this.unmuteButton.visible = true;
  };
  unmute = (skipSend) => {
    if (!skipSend) {
      Multiplayer.instance.sendCustomEvent(this.uuid, "unmute", true);
    }
    this.positionalAudio.setVolume(0.8);
    this.muteButton.visible = true;
    this.unmuteButton.visible = false;
  };

  hide = (skipSend) => {
    if (!skipSend) {
      Multiplayer.instance.sendCustomEvent(this.uuid, "hide", true);
    }
    // this.board.material = this.videoMaterial;
    this.video.pause();
    this.positionalAudio.pause();
    this.playButton.visible = true;
    this.pauseButton.visible = false;
  };
  show = (skipSend) => {
    if (!skipSend) {
      Multiplayer.instance.sendCustomEvent(this.uuid, "show", true);
    }
    // this.board.material = this.videoMaterial;
    this.video.play();
    this.positionalAudio.play();
    this.playButton.visible = false;
    this.pauseButton.visible = true;
  };

  //todo - need to hook this up
  hideVideoViewerGreenscreen(enabled, send) {
    if (send) {
      Multiplayer.instance.sendCustomEvent(this.uuid, "hideVideoViewerGreenscreen");
    }
  }

  //todo - need to hook this up
  showVideoViewerGreenscreen(enabled, send) {
    if (send) {
      Multiplayer.instance.sendCustomEvent(this.uuid, "showVideoViewerGreenscreen");
    }
  }

  toggleGreenScreen(enabled, send) {
    this.videoMaterial.uniforms.enabled.value = enabled;
    if (send) {
      Multiplayer.instance.sendCustomEvent(this.uuid, "toggleGreenScreen", this.videoMaterial.uniforms.enabled.value);
    }
  }

  vertexShader() {
    return `
            varying vec2 vUv;

            void main() {
                vUv = uv;

                vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
                gl_Position = projectionMatrix * modelViewPosition;
            }
        `;
  }

  fragmentShader() {
    return `
            uniform sampler2D tex;
            uniform bool enabled;
            varying vec2 vUv;

            void main () {
                mediump vec3 inColour = texture2D(tex, vUv).rgb;
                if (!enabled) {
                    gl_FragColor = vec4(inColour, 1.0);
                } else {
                    mediump vec3 green = vec3(0.0, 1.0, 0.0);
                    mediump float a = (length(inColour - green) - 0.5) * 7.0;
                    gl_FragColor = vec4(inColour, a);
                }
            }
        `;
  }
}
